import { Component } from "react";

const PageHasBeenForceRefreshed = "page-has-been-force-refreshed";

const retryPageLoading = () => {
  const pageHasAlreadyBeenForceRefreshed = JSON.parse(
    window.localStorage.getItem(PageHasBeenForceRefreshed) || "false"
  );

  if (!pageHasAlreadyBeenForceRefreshed) {
    window.localStorage.setItem(PageHasBeenForceRefreshed, "true");
    return window.location.reload();
  } else {
    window.localStorage.setItem(PageHasBeenForceRefreshed, "false");
  }
};

export class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, timer: true };
  }
  componentDidCatch(error, info) {
    retryPageLoading();
    this.setState({ hasError: true, timer: true });
    setTimeout(() => {
      this.setState({ timer: false });
    }, 3000);
    console.log(error, info);
  }
  render() {
    if (this.state.hasError) {
      return this.state.timer ? (
        <div className="p-2 color-black">redirecting...</div>
      ) : (
        <div className="p-2 color-black">
          We are sorry for the inconvenience caused. New build has been
          deployed.
          <br />
          Please clear you browser cache and refresh this page.
        </div>
      );
    }
    return this.props.children;
  }
}
