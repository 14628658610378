import React from "react";
import { useHistory } from "react-router";
import makeEventImage from "../assets/images/makeEventImage.png";
interface ContainerProps {}

const MakeEvent: React.FC<ContainerProps> = () => {
  let history = useHistory();
  return (
    <div className="make-own-event">
      <div className="left-img">
        <img src={makeEventImage} alt="Make Event" />
      </div>
      <div className="right-content">
        <div className="text-content">
          <h1 className="content-heading">Make Your Own Event</h1>
          <div className="content-desc">
            Welcome to our event creation platform! With just a few clicks, you
            can create your own customized event and invite your friends,
            family, or colleagues to join.
          </div>
        </div>
        <div className="action-btn">
          <button
            className="action-btn-common"
            onClick={() => {
              history.push("/signup");
            }}
          >
            Create Events
          </button>
        </div>
      </div>
    </div>
  );
};

export default MakeEvent;
