import React from "react";
import { useHistory } from "react-router";

interface ContainerProps {}

const UpcomingEventsFooter: React.FC<ContainerProps> = () => {
  let history = useHistory();

  return (
    <div className="footer">
      <div className="end-line">
        <div className="watermark">
          <span
            className="conjusted cursor-pointer"
            onClick={() => {
              history.push("/home");
            }}
          >
            <span className="dance">
              {" "}
              <span className="highlight-text f-120p">B</span>
              <span>a</span>
              <span>s</span>
              <span>h</span>
            </span>{" "}
            <span className="dance-rev">
              <span className="highlight-text f-120p">I</span>
              <span>n</span>
            </span>{" "}
            <span className="dance">
              <span className="highlight-text f-120p">J</span>
              <span>a</span>
              <span>i</span>
              <span>p</span>
              <span>u</span>
              <span>r</span>
            </span>
          </span>
        </div>
        <div className="links">
          <ul className="links-list">
            <li className="highlighted">
              <a href="mailto:support@bashinjaipur.com" className="nostyle">
                Contact Us
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className="copyright">Copyright © 2023. DailyCodeSolutions</div>
    </div>
  );
};

export default UpcomingEventsFooter;
