import React from "react";
import { useHistory } from "react-router";

interface ContainerProps {}

const StartEnterpriseSection: React.FC<ContainerProps> = () => {
  let history = useHistory();
  return (
    <div className="start-enterprise-parent">
      <div className="start-enterprise-section">
        <div className="content-grid">
          <div className="left-section">
            <div className="text-content">
              <h1 className="content-head">Join The Fun</h1>
              <p className="content-desc">
                If you can’t wait to join us for a funfilled party and are
                willing to explore and navigate the affair, we’d love to get you
                started.
              </p>
            </div>
            <div className="request-access-btn">
              <button
                className="action-btn-common"
                onClick={() => {
                  history.push("/signup");
                }}
              >
                Sign Up Now
              </button>
            </div>
          </div>
          <div className="right-section"></div>
        </div>
        <div className="end-line">
          <div className="watermark">
            <span
              className="conjusted cursor-pointer"
              onClick={() => {
                history.push("/home");
              }}
            >
              <span className="dance">
                {" "}
                <span className="highlight-text f-120p">B</span>
                <span>a</span>
                <span>s</span>
                <span>h</span>
              </span>{" "}
              <span className="dance-rev">
                <span className="highlight-text f-120p">I</span>
                <span>n</span>
              </span>{" "}
              <span className="dance">
                <span className="highlight-text f-120p">J</span>
                <span>a</span>
                <span>i</span>
                <span>p</span>
                <span>u</span>
                <span>r</span>
              </span>
            </span>
          </div>
          <div className="links">
            <ul className="links-list">
              <li className="highlighted">
                <a href="mailto:support@bashinjaipur.com" className="nostyle">
                  Contact Us
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="copyright">Copyright © 2023. DailyCodeSolutions</div>
      </div>
    </div>
  );
};

export default StartEnterpriseSection;
