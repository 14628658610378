import { Button, Nav, NavItem } from "reactstrap";
import Logo from "./Logo";
import { Component } from "react";
import { Link } from "react-router-dom";
import { postCall } from "../http-services";
import { BackendAPIs, getBaseApi } from "../store/constant";
import { connect } from "react-redux";
import actionTypes from "../store/actions";

class Sidebar extends Component {
  state = {
    navigation: [],
  };

  componentDidMount() {
    postCall(this.props.setLoading)(
      getBaseApi() + BackendAPIs.auth.menuItems,
      {}
    ).then((resp) => {
      this.setState({ navigation: resp.data || [] });
      this.props.setNavigation(resp.data || []);
    });
  }

  showMobilemenu() {
    document.getElementById("sidebarArea").classList.toggle("showSidebar");
  }

  render() {
    return (
      <div>
        <div className="p-3 d-flex align-items-center height-6rem bb-2">
          <Logo />
          <Button
            size="sm"
            className="ms-auto d-lg-none btn-close"
            onClick={() => this.showMobilemenu()}
          ></Button>
        </div>
        <div className="p-3 mt-2 nav-content">
          <Nav vertical className="sidebarNav">
            {this.state.navigation.map((navi, index) => (
              <NavItem key={index} className="sidenav-bg">
                <Link
                  to={navi.href}
                  className={
                    window.location.pathname === navi.href
                      ? "text-primary nav-link py-3 flex-center"
                      : "nav-link text-secondary py-3 flex-center"
                  }
                >
                  <i className={navi.icon}></i>
                  <span className="ms-3 d-inline-block">{navi.title}</span>
                </Link>
              </NavItem>
            ))}
          </Nav>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    setNavigation: (navigation) =>
      dispatch({ type: actionTypes.SET_NAVIGATION, navigation }),
    setLoading: (isLoading) =>
      dispatch({
        type: actionTypes.SET_LOADING,
        isLoading,
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
