import { Component } from "react";
import { Modal, ModalBody } from "reactstrap";
import EventBody from "./EventBody";

class EventModal extends Component {
  render() {
    return (
      <Modal
        isOpen={this.props.show}
        toggle={this.props.onHide}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <ModalBody>
          <div className="modal-actions">
            <i
              className="fa fa-times text-danger cursor-pointer"
              aria-hidden="true"
              onClick={this.props.onHide}
            ></i>
          </div>
          <EventBody eventObj={this.props.eventObj} />
        </ModalBody>
      </Modal>
    );
  }
}

export default EventModal;
