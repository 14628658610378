import React from "react";
import { ReactComponent as PolygonEllipse } from "../assets/svgs/polygonEllipse.svg";
// import { ReactComponent as AnnaPhoto } from "../assets/svgs/annaPhoto.svg";
// import { ReactComponent as DillonPhoto } from "../assets/svgs/dillonPhoto.svg";
// import { ReactComponent as KasPhoto } from "../assets/svgs/kasPhoto.svg";
import Girl2 from "../assets/images/girl2.png";

interface ContainerProps {}

const MembersSection: React.FC<ContainerProps> = () => {
  return (
    <div className="member-section">
      <div className="page-body">
        <div className="section-left">
          <div className="left-Icon">
            <PolygonEllipse />
          </div>
          <h1 className="left-heading">
            Bring Your Vision to Life and Make it Happen
          </h1>
          <p className="left-content">
            The first step of realizing your vision and turning your event ideas
            into reality. Meeting new people can be a great way to expand your
            social circle.
          </p>
        </div>
        <div className="bg-party">
          <img className="party-girl1" src={Girl2} alt="Jumping Girl" />
        </div>
        {/* <div className="bg-party">
          <div className="name-card">
            <h1 className="name-card-heading">Members</h1>
            <div className="name-card-content">
              <div className="member-group">
                <div className="member-img">
                  <DillonPhoto />
                </div>
                <div className="member-info">
                  <h2 className="member-name">Dillon Kydd</h2>
                  <p className="member-shares">100 SHARES</p>
                </div>
              </div>
              <div className="member-group">
                <div className="member-img">
                  <AnnaPhoto />
                </div>
                <div className="member-info">
                  <h2 className="member-name">Anna Olsen</h2>
                  <p className="member-shares">100 SHARES</p>
                </div>
              </div>
              <div className="member-group">
                <div className="member-img">
                  <KasPhoto />
                </div>

                <div className="member-info">
                  <h2 className="member-name">Kas Moss</h2>
                  <p className="member-shares">100 SHARES</p>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default MembersSection;
