import { connect } from "react-redux";
import { Component } from "react";
import { Col, Row } from "reactstrap";
import Aux from "../../hoc/_Aux";
import { postCall } from "../../http-services";
import { BackendAPIs, getBaseApi } from "../../store/constant";
import actionTypes from "../../store/actions";

class GlobalProps extends Component {
  state = {
    propsTypes: [],
    selectedPropsType: "",
    propsKeys: [],
    selectedPropsKey: "",
    propsMap: {},
    selectedProp: {},
    editMode: false,
    editingVal: "",
    errorText: "",
  };

  componentDidMount() {
    postCall(this.props.setLoading)(
      getBaseApi() + BackendAPIs.admin.globalPropsTypes,
      {}
    ).then((resp) => {
      if (resp && resp.data && resp.data.length) {
        this.setState({
          propsTypes: resp.data,
          propsKeys: [],
        });

        this.propsTypeChanged(resp.data[0]);
      }
    });
  }

  propsTypeChanged = function (val, key) {
    postCall(this.props.setLoading)(
      getBaseApi() + BackendAPIs.admin.globalPropsKeys,
      {
        type: val,
      }
    ).then((resp) => {
      if (resp && resp.data && resp.data.length) {
        this.setState({
          selectedPropsType: val,
          propsKeys: resp.data,
          propsMap: resp.propsMap,
          selectedPropsKey: key ? key : resp.data[0],
          selectedProp: key ? resp.propsMap[key] : resp.propsMap[resp.data[0]],
          editMode: false,
          editingVal: "",
        });
      } else {
        this.setState({
          selectedPropsType: val,
          propsKeys: [],
          propsMap: {},
          editingVal: "",
        });
      }
    });
  };

  propsKeyChanged = function (val) {
    this.setState({
      selectedPropsKey: val,
      selectedProp: this.state.propsMap[val],
      editMode: false,
    });
  };

  getPropDisplay() {
    if (this.state.selectedProp && this.state.selectedProp.value) {
      if (
        this.state.selectedProp.valueType === "Object" ||
        this.state.selectedProp.valueType === "Array"
      ) {
        return JSON.stringify(this.state.selectedProp.value, null, 4);
      }
      return this.state.selectedProp.value;
    }
    return null;
  }

  saveValue() {
    let selectedProp = this.state.selectedProp;
    let valueToUpdate = this.state.editingVal;
    if (
      selectedProp.valueType === "Object" ||
      selectedProp.valueType === "Array"
    ) {
      try {
        JSON.parse(valueToUpdate);
      } catch (e) {
        this.setState({ errorText: "Not a valid JSON" });
        return;
      }
      if (
        selectedProp.valueType === "Array" &&
        !JSON.parse(valueToUpdate).length
      ) {
        this.setState({ errorText: "Not a valid Array" });
        return;
      } else {
        this.setState({ errorText: "" });
      }
    } else if (selectedProp.valueType === "Number") {
      if (isNaN(valueToUpdate)) {
        this.setState({ errorText: "Not a valid Number" });
        return;
      } else {
        this.setState({ errorText: "" });
      }
    }

    postCall(this.props.setLoading)(
      getBaseApi() + BackendAPIs.admin.updateGlobalProps,
      {
        value: valueToUpdate,
        selectedProp,
      }
    ).then((resp) => {
      const val = this.state.selectedPropsType;
      this.propsTypeChanged(val, selectedProp.key);
    });
  }

  render() {
    return (
      <Aux>
        <Row>
          <Col>
            <form>
              <div className="form-group">
                <label className="form-label">Property Types</label>
                <select
                  className="form-control"
                  value={this.state.selectedPropsType}
                  onChange={(e) => {
                    this.propsTypeChanged(e.target.value);
                  }}
                >
                  {this.state.propsTypes.map((d, i) => {
                    return (
                      <option value={d} key={i}>
                        {d}
                      </option>
                    );
                  })}
                </select>
              </div>
            </form>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="form-group">
              <label className="form-label">Property Keys</label>
              <select
                className="form-control"
                value={this.state.selectedPropsKey}
                onChange={(e) => {
                  this.propsKeyChanged(e.target.value);
                }}
              >
                {this.state.propsKeys.map((d, i) => {
                  return (
                    <option value={d} key={i}>
                      {d}
                    </option>
                  );
                })}
              </select>
            </div>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col>
            {this.state.errorText ? (
              <div className="alert alert-danger">{this.state.errorText}</div>
            ) : null}
            <div className="icon-warpper">
              {this.state.editMode
                ? [
                    this.state.editingVal ? (
                      <i
                        className="fa fa-save icon-right"
                        onClick={() => this.saveValue()}
                        key="0"
                      ></i>
                    ) : null,
                    <textarea
                      className="prop-text-area form-control"
                      as="textarea"
                      value={this.state.editingVal}
                      onChange={(e) => {
                        this.setState({ editingVal: e.target.value });
                      }}
                      key="2"
                    ></textarea>,
                  ]
                : [
                    <i
                      key="1"
                      className="fa fa-edit icon-right"
                      onClick={() => {
                        this.setState({
                          editMode: true,
                          editingVal: this.getPropDisplay(),
                        });
                      }}
                    ></i>,
                    <pre className="prop-pre" key="2">
                      {this.getPropDisplay()}
                    </pre>,
                  ]}
            </div>
          </Col>
        </Row>
      </Aux>
    );
  }
}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    setLoading: (isLoading) =>
      dispatch({
        type: actionTypes.SET_LOADING,
        isLoading,
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GlobalProps);
