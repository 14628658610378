import { Component } from "react";
import { Col, Row, Button } from "reactstrap";
import Aux from "../../hoc/_Aux";
import { postCall } from "../../http-services";
import { BackendAPIs, getBaseApi } from "../../store/constant";
import actionTypes from "../../store/actions";
import { connect } from "react-redux";

class SideNavs extends Component {
  state = {
    users: [],
    selectedUser: "",
    editMode: false,
    editingVal: "",
    errorText: "",
    selectedSideNav: [],
  };

  componentDidMount() {
    postCall(this.props.setLoading)(
      getBaseApi() + BackendAPIs.admin.getUsersList,
      {}
    ).then((resp) => {
      if (resp && resp.data && resp.data.length) {
        this.setState({
          users: resp.data,
          accounts: [],
        });

        this.userChanged(resp.data[0]);
      }
    });
  }

  userChanged = function (val, key) {
    postCall(this.props.setLoading)(
      getBaseApi() + BackendAPIs.admin.getSideNav,
      {
        email: val,
      }
    ).then((resp) => {
      if (resp && resp.data) {
        this.setState({
          selectedUser: val,
          editMode: false,
          editingVal: "",
          selectedSideNav: resp.data,
        });
      } else {
        this.setState({
          selectedUser: val,
          editMode: false,
          editingVal: "",
          selectedSideNav: [],
        });
      }
    });
  };

  getAccountDisplay() {
    if (this.state.selectedSideNav) {
      return JSON.stringify(this.state.selectedSideNav, null, 4);
    }
    return null;
  }

  saveValue() {
    let valueToUpdate = this.state.editingVal;
    let selectedUser = this.state.selectedUser;
    try {
      JSON.parse(valueToUpdate);
    } catch (e) {
      this.setState({ errorText: "Not a valid JSON" });
      return;
    }

    postCall(this.props.setLoading)(
      getBaseApi() + BackendAPIs.admin.updateSideNav,
      {
        value: valueToUpdate,
        email: selectedUser,
      }
    ).then((resp) => {
      this.setState({
        editMode: false,
        selectedSideNav: resp.data,
      });
    });
  }

  fillDefaultSideNav() {
    postCall(this.props.setLoading)(
      getBaseApi() + BackendAPIs.admin.setDefaultSideNav,
      {
        email: this.state.selectedUser,
      }
    ).then((resp) => {
      this.setState({
        editMode: false,
        selectedSideNav: resp.data,
      });
    });
  }

  removeUserSpecificSideNav() {
    let selectedUser = this.state.selectedUser;

    postCall(this.props.setLoading)(
      getBaseApi() + BackendAPIs.admin.removeSideNav,
      {
        email: selectedUser,
      }
    ).then((resp) => {
      this.setState({
        editMode: false,
        selectedSideNav: [],
      });
    });
  }
  render() {
    return (
      <Aux>
        <Row>
          <Col>
            <form>
              <div className="form-group">
                <label className="form-label">Users</label>
                <select
                  className="form-control"
                  value={this.state.selectedUser}
                  onChange={(e) => {
                    this.userChanged(e.target.value);
                  }}
                >
                  {this.state.users.map((d, i) => {
                    return (
                      <option value={d} key={i}>
                        {d}
                      </option>
                    );
                  })}
                </select>
              </div>
            </form>
          </Col>
        </Row>
        <br />
        {this.state.selectedUser && !this.state.selectedSideNav ? (
          <Row>
            <Col>
              <Button
                className="add-new-user"
                variant="outline-dark"
                size="sm"
                onClick={() => this.fillDefaultSideNav()}
              >
                Fill Default
              </Button>
            </Col>{" "}
          </Row>
        ) : null}
        <hr />
        {this.state.selectedUser && this.state.selectedSideNav ? (
          <Row>
            <Col>
              {this.state.errorText ? (
                <div className="alert alert-danger">{this.state.errorText}</div>
              ) : null}
              <div className="icon-warpper">
                {this.state.editMode
                  ? [
                      this.state.editingVal ? (
                        <i
                          className="fa fa-save icon-right"
                          onClick={() => this.saveValue()}
                          key="0"
                        ></i>
                      ) : null,
                      <textarea
                        className="prop-text-area form-control"
                        value={this.state.editingVal}
                        onChange={(e) => {
                          this.setState({ editingVal: e.target.value });
                        }}
                        key="2"
                      ></textarea>,
                    ]
                  : [
                      <span key="1" className="icon-right-wrapper">
                        <i
                          className="fa fa-edit text-info pr-2"
                          onClick={() => {
                            this.setState({
                              editMode: true,
                              editingVal: this.getAccountDisplay(),
                            });
                          }}
                        ></i>
                        <i
                          className="fa fa-trash text-danger"
                          onClick={() => {
                            this.removeUserSpecificSideNav();
                          }}
                        ></i>
                      </span>,
                      <pre className="prop-pre" key="2">
                        {this.getAccountDisplay()}
                      </pre>,
                    ]}
              </div>
            </Col>
          </Row>
        ) : null}
      </Aux>
    );
  }
}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    setLoading: (isLoading) =>
      dispatch({
        type: actionTypes.SET_LOADING,
        isLoading,
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SideNavs);
