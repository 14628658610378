import logo from "../assets/images/logo_pink.png";
import { Link } from "react-router-dom";

const Logo = () => {
  return (
    <Link to="/" className="brand-logo">
      <img src={logo} alt="logo" style={{ height: "5rem" }} />
      <div className="logo-text">
        <span>
          <span className="dance">
            {" "}
            <span className="highlight-text f-120p">B</span>
            <span>a</span>
            <span>s</span>
            <span>h</span>
          </span>{" "}
          <span className="dance-rev">
            <span className="highlight-text f-120p">I</span>
            <span>n</span>
          </span>{" "}
          <span className="dance">
            <span className="highlight-text f-120p">J</span>
            <span>a</span>
            <span>i</span>
            <span>p</span>
            <span>u</span>
            <span>r</span>
          </span>
        </span>
      </div>
    </Link>
  );
};

export default Logo;
