import React from "react";
import actionTypes from "../../store/actions";
import { connect } from "react-redux";
import Header from "../../components/Header";
import Cards from "../../components/Cards";
import MembersSection from "../../components/MembersSection";
import OnboardUserSection from "../../components/OnboardUserSection";
import ContributorsSection from "../../components/ContributorsSection";
import HowToImproveSection from "../../components/HowToImproveSection";
import StartEnterpriseSection from "../../components/StartEnterpriseSection";

interface HomeProps {}

const Home: React.FC<HomeProps> = () => {
  return (
    <div className="home">
      <Header />
      <Cards />
      <MembersSection />
      <OnboardUserSection />
      <ContributorsSection />
      <HowToImproveSection />
      <StartEnterpriseSection />
    </div>
  );
};

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    setLoading: (isLoading: any) =>
      dispatch({
        type: actionTypes.SET_LOADING,
        isLoading,
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
