import React from "react";
import { ReactComponent as EllipseIntersect } from "../assets/svgs/cardIcon.svg";
import { ReactComponent as PolygonIntersect } from "../assets/svgs/cardPolygon.svg";
import { ReactComponent as EllipseStar } from "../assets/svgs/cardEllipse.svg";

interface ContainerProps {}

const Cards: React.FC<ContainerProps> = () => {
  return (
    <div className="card-section">
      <div className="card-heading">
        <h1 className="header-text">Revolutionizing the Event Experience</h1>
        <p className="desc-text">
          We are here to change the way people think about and attend events.
          This is a perfect destination for people who are looking for something
          unique and exciting, and who want to experience events in a new and
          different way.
        </p>
      </div>
      <div className="card-row">
        <div className="card-item">
          <div className="card-img">
            <EllipseIntersect />
          </div>
          <h1 className="card-title">Innovation</h1>
          <p className="card-desc">
            Introducing new and creative ideas to the event planning industry,
            such as interactive elements, and out-of-the-box themes to create an
            entirely new experience for attendees.
          </p>
        </div>
        <div className="card-item">
          <div className="card-img">
            <PolygonIntersect />
          </div>
          <h1 className="card-title">Convenience</h1>
          <p className="card-desc">
            An easy-to-use platform for people to register and join events,
            making the process of attending events more convenient and
            hassle-free.
          </p>
        </div>
        <div className="card-item">
          <div className="card-img">
            <EllipseStar />
          </div>
          <h1 className="card-title">Inclusivity</h1>
          <p className="card-desc">
            Focused on creating events that are inclusive and welcoming to
            people from all backgrounds and communities.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Cards;
