import React, { useEffect, useState } from "react";
import { postCall } from "../http-services";
import { connect } from "react-redux";
import actionTypes from "../store/actions";
import { BackendAPIs, getBaseApi } from "../store/constant";
import moment from "moment";
import Logo from "../assets/images/logo_pink.png";
import EventModal from "./Event/EventModal";
interface ContainerProps {
  setLoading: any;
}

const EventList: React.FC<ContainerProps> = ({ setLoading }) => {
  let [events, setEvents] = useState([]);
  let [showModal, setShowModal] = useState(false);
  let [eventObj, setEventObj] = useState(null);
  useEffect(() => {
    postCall(setLoading)(getBaseApi() + BackendAPIs.open.getEvents, {}).then(
      (resp) => {
        if (resp.events) {
          setEvents(resp.events);
        }
      }
    );
    // eslint-disable-next-line
  }, []);

  return (
    <div className="event-list-section">
      <div className="section-header">
        <h1 className="upcoming-events">Upcoming Events</h1>
      </div>
      <div className="events">
        {!events.length ? (
          <div className="alert alert-info">No Active Events.</div>
        ) : (
          <div className="events-row">
            {events.map((d: any, i) => {
              return (
                <div
                  key={i}
                  className="event-card cursor-pointer"
                  onClick={() => {
                    setEventObj(d);
                    setShowModal(true);
                  }}
                >
                  <div className="event-img">
                    <img src={d.imagePath || Logo} alt="christmas" />
                  </div>
                  <div className="event-desc">
                    <div className="event-date">
                      <div className="month">
                        {moment(d.eventDate).format("MMM")}
                      </div>
                      <div className="date">
                        {moment(d.eventDate).format("DD")}
                      </div>
                    </div>
                    <div className="event-details">
                      <h1 className="detail-heading">{d.title}</h1>
                      <p className="detail">{d.shortDesc}</p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
      {showModal ? (
        <EventModal
          show={showModal}
          onHide={() => {
            setShowModal(false);
          }}
          eventObj={eventObj}
        />
      ) : null}
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    isLoading: state.isLoading,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    setLoading: (isLoading: any) =>
      dispatch({
        type: actionTypes.SET_LOADING,
        isLoading,
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EventList);
