import {
  Button,
  Form,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
} from "reactstrap";
import { Component } from "react";

class DeleteUserModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: props.modalData.data.email,
      typedEmail: "",
    };
  }

  componentDidUpdate(oldProps) {
    if (oldProps.modalData.data.email !== this.props.modalData.data.email) {
      this.setState({
        email: this.props.modalData.data.email,
        typedEmail: "",
      });
    }
  }

  changeEmail($event) {
    this.setState({ typedEmail: $event.target.value });
  }

  deleteUser() {
    const { email } = this.state;
    this.props.deleteUser({ email });
  }

  render() {
    return (
      <Modal
        isOpen={this.props.show}
        toggle={this.props.onHide}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <ModalHeader>
          <div id="contained-modal-title-vcenter">
            {this.props.modalData.heading}
          </div>
        </ModalHeader>
        <ModalBody className="add-user-form-body">
          <p>
            Are you sure you want to delete user (
            <code>{this.state.email}</code>) ?.
          </p>
          <Form>
            <FormGroup>
              <label className="label form-lable">Confirm Email Address</label>
              <input
                className="form-control"
                type="email"
                placeholder="name@example.com"
                value={this.state.typedEmail}
                onChange={this.changeEmail.bind(this)}
              />
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button size="sm" variant="outline-dark" onClick={this.props.onHide}>
            Close
          </Button>
          <Button
            size="sm"
            variant="dark"
            disabled={this.state.email !== this.state.typedEmail}
            onClick={this.deleteUser.bind(this)}
          >
            Delete
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default DeleteUserModal;
