import { Button } from "reactstrap";
import { Component } from "react";
import { Col, Row } from "reactstrap";
import Aux from "../../hoc/_Aux";
import { postCall } from "../../http-services";
import { BackendAPIs, getBaseApi } from "../../store/constant";
import CustomTable from "../../UIElements/CustomTable";
import { connect } from "react-redux";
import actionTypes from "../../store/actions";
import AddAdminModal from "./AddAdminModal";
import DeleteUserModal from "./DeleteUserModal";

class AdminEmails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      adminList: [],
      columns: [
        { title: "Id", name: "_id", type: "string" },
        { title: "Name", name: "name", type: "string" },
        { title: "Email", name: "email", type: "string" },
      ],
      criteria: {
        currentPage: 1,
        totalPages: 1,
        sortColumn: null,
        sortDirection: null,
        filteredColumns: [],
        filterValues: {},
      },
      columnOps: {},
      showModal: false,
      showDeleteModal: false,
      modalData: {
        heading: "",
        data: {
          name: "",
          email: "",
        },
      },
      deleteModalData: {
        heading: "",
        data: {
          email: "",
        },
      },
    };
  }

  componentDidMount() {
    postCall(this.props.setLoading)(
      getBaseApi() + BackendAPIs.admin.email,
      {}
    ).then((resp) => {
      this.setState({
        adminList: resp.data,
      });
    });
  }
  addNewUserModal() {
    this.setState({
      modalData: {
        heading: "Create New Admin",
        data: {
          name: "",
          email: "",
        },
        editMode: false,
      },
      showModal: true,
    });
  }

  setModalShow(showModal) {
    this.setState({ showModal });
  }

  setDeleteModalShow(showDeleteModal) {
    this.setState({ showDeleteModal });
  }

  saveUser(data) {
    postCall(this.props.setLoading)(
      getBaseApi() + BackendAPIs.admin.add,
      data
    ).then((resp) => {
      postCall(this.props.setLoading)(
        getBaseApi() + BackendAPIs.admin.email,
        {}
      ).then((resp) => {
        this.setState({
          adminList: resp.data,
          showModal: false,
        });
      });
    });
  }

  openDeleteUserconfirmationModal(admin) {
    this.setState({
      deleteModalData: {
        heading: "Delete User - " + admin.email,
        data: JSON.parse(JSON.stringify(admin)),
      },
      showDeleteModal: true,
    });
  }

  deleteUser({ email }) {
    postCall(this.props.setLoading)(getBaseApi() + BackendAPIs.admin.delete, {
      email,
    }).then((user) => {
      postCall(this.props.setLoading)(
        getBaseApi() + BackendAPIs.admin.email,
        {}
      ).then((resp) => {
        this.setState({
          adminList: resp.data,
          showDeleteModal: false,
        });
      });
    });
  }

  render() {
    let adminList = this.state.adminList;
    let actions = [
      {
        name: "delete",
        text: "Delete",
        variant: "outline-danger",
        handler: (admin) => {
          this.openDeleteUserconfirmationModal(admin);
        },
      },
    ];

    return (
      <Aux>
        <Row>
          <Col>
            <Button
              className="add-new-user"
              variant="outline-dark"
              size="sm"
              onClick={() => this.addNewUserModal()}
            >
              Add New Admin
            </Button>
          </Col>
        </Row>
        <br />
        {adminList && adminList.length > 0 ? (
          <Row>
            <Col>
              <CustomTable
                columns={this.state.columns}
                criteria={this.state.criteria}
                data={this.state.adminList}
                columnOps={this.state.columnOps}
                wapperClassName="height60vh"
                actions={actions}
              ></CustomTable>

              <AddAdminModal
                show={this.state.showModal}
                onHide={() => this.setModalShow(false)}
                modalData={this.state.modalData}
                save={(data) => {
                  this.saveUser(data);
                }}
              />

              <DeleteUserModal
                show={this.state.showDeleteModal}
                onHide={() => this.setDeleteModalShow(false)}
                modalData={this.state.deleteModalData}
                deleteUser={(data) => {
                  this.deleteUser(data);
                }}
              />
            </Col>
          </Row>
        ) : (
          <Row>
            <Col>
              <AddAdminModal
                show={this.state.showModal}
                onHide={() => this.setModalShow(false)}
                modalData={this.state.modalData}
                save={(data) => {
                  this.saveUser(data);
                }}
              />
            </Col>
          </Row>
        )}
      </Aux>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    collapseMenu: state.collapseMenu,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setLoading: (isLoading) =>
      dispatch({
        type: actionTypes.SET_LOADING,
        isLoading,
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminEmails);
