import { Component } from "react";
import Skeleton from "react-loading-skeleton";
import { connect } from "react-redux";

class CustomSkeleton extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    let { type, data = {}, height } = this.props;
    let toReturn = <Skeleton variant="rect" height={height || 100}></Skeleton>;
    let h = 100;

    switch (type) {
      case "catalog":
        toReturn = (
          <div className="sk-grid-1 mt-2">
            <div className="pb-3">
              <Skeleton variant="rect" height={100}></Skeleton>
            </div>
            <div className="sk-grid-2">
              <div>
                <Skeleton
                  variant="rect"
                  height={350}
                  width={350}
                  className="m-auto"
                ></Skeleton>
              </div>
              <div className="pt-5">
                <div className="pb-3 sk-grid-2">
                  <div className="pr-5">
                    <Skeleton variant="rect" height={25}></Skeleton>
                  </div>
                  <div className="pl-5">
                    <Skeleton variant="rect" height={25}></Skeleton>
                  </div>
                </div>
                <div className="pb-3 sk-grid-2">
                  <div className="pr-5">
                    <Skeleton variant="rect" height={25}></Skeleton>
                  </div>
                  <div className="pl-5">
                    <Skeleton variant="rect" height={25}></Skeleton>
                  </div>
                </div>
                <div className="pb-3 sk-grid-2">
                  <div className="pr-5">
                    <Skeleton variant="rect" height={25}></Skeleton>
                  </div>
                  <div className="pl-5">
                    <Skeleton variant="rect" height={25}></Skeleton>
                  </div>
                </div>
                <div className="pb-3 sk-grid-2">
                  <div className="pr-5">
                    <Skeleton variant="rect" height={25}></Skeleton>
                  </div>
                  <div className="pl-5">
                    <Skeleton variant="rect" height={25}></Skeleton>
                  </div>
                </div>
                <div className="pb-3 sk-grid-2">
                  <div className="pr-5">
                    <Skeleton variant="rect" height={25}></Skeleton>
                  </div>
                  <div className="pl-5">
                    <Skeleton variant="rect" height={25}></Skeleton>
                  </div>
                </div>
                <div className="pb-3 sk-grid-2">
                  <div className="pr-5">
                    <Skeleton variant="rect" height={25}></Skeleton>
                  </div>
                  <div className="pl-5">
                    <Skeleton variant="rect" height={25}></Skeleton>
                  </div>
                </div>
                <div className="pb-3 sk-grid-2">
                  <div className="pr-5">
                    <Skeleton variant="rect" height={25}></Skeleton>
                  </div>
                  <div className="pl-5">
                    <Skeleton variant="rect" height={25}></Skeleton>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
        break;
      case "catalog-alt":
        toReturn = (
          <div className="sk-grid-1 mt-2">
            <div className="pb-3">
              <Skeleton variant="rect" height={100}></Skeleton>
            </div>
            <div className="sk-grid-2">
              <div>
                <Skeleton
                  variant="rect"
                  height={400}
                  width={400}
                  className="m-auto"
                ></Skeleton>
              </div>
              <div>
                <div className="pb-4">
                  <Skeleton variant="rect" height={150}></Skeleton>
                </div>
                <div className="pb-3 sk-grid-2">
                  <div className="pr-5">
                    <Skeleton variant="rect" height={25}></Skeleton>
                  </div>
                  <div className="pl-5">
                    <Skeleton variant="rect" height={25}></Skeleton>
                  </div>
                </div>
                <div className="pb-3 sk-grid-2">
                  <div className="pr-5">
                    <Skeleton variant="rect" height={25}></Skeleton>
                  </div>
                  <div className="pl-5">
                    <Skeleton variant="rect" height={25}></Skeleton>
                  </div>
                </div>
                <div className="pb-3 sk-grid-2">
                  <div className="pr-5">
                    <Skeleton variant="rect" height={25}></Skeleton>
                  </div>
                  <div className="pl-5">
                    <Skeleton variant="rect" height={25}></Skeleton>
                  </div>
                </div>
                <div className="pb-3 sk-grid-2">
                  <div className="pr-5">
                    <Skeleton variant="rect" height={25}></Skeleton>
                  </div>
                  <div className="pl-5">
                    <Skeleton variant="rect" height={25}></Skeleton>
                  </div>
                </div>
                <div className="pb-3 sk-grid-2">
                  <div className="pr-5">
                    <Skeleton variant="rect" height={25}></Skeleton>
                  </div>
                  <div className="pl-5">
                    <Skeleton variant="rect" height={25}></Skeleton>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
        break;
      case "local-filter":
        let { totalItems = 5 } = data || {};
        let filters = [];
        for (let i = 0; i < totalItems; i++) {
          filters.push(
            <Skeleton variant="rect" height={20} key={i}></Skeleton>
          );
        }
        toReturn = (
          <div className="sk-local-filter sk-grid-1">
            <div className="p-2">
              <Skeleton variant="rect" height={30}></Skeleton>
            </div>
            {filters}

            <div className="sk-grid-2 p-2">
              <div></div>
              <div className="sk-grid-2 p-2">
                <Skeleton variant="rect" height={30}></Skeleton>
                <Skeleton variant="rect" height={30}></Skeleton>
              </div>
            </div>
          </div>
        );
        break;
      case "top-filter":
        let { count = 4 } = data || {};
        let values = [];
        for (let i = 0; i < count; i++) {
          values.push(
            <Skeleton variant="rect" height={height || 40} key={i}></Skeleton>
          );
        }
        toReturn = <div className={"sk-grid-" + count}>{values}</div>;
        break;
      case "table":
        let { rows = 10, columns = 9 } = data || {};
        columns = columns > 9 ? 9 : columns;
        let table = [];
        for (let i = 0; i < rows; i++) {
          let cols = [];
          for (let j = 0; j < columns; j++) {
            cols.push(
              <div key={"c" + j}>
                <Skeleton variant="rect" height={30}></Skeleton>
              </div>
            );
          }
          table.push(
            <div className={"pt-1 pb-1 sk-grid-" + columns} key={"r" + i}>
              {cols}
            </div>
          );
        }
        toReturn = (
          <div className="sk-table">
            <div className="p-2 sk-head">
              <Skeleton variant="rect" height={30}></Skeleton>
            </div>
            <div className="p-2 sk-body">{table}</div>
            <div className="p-2 sk-foot">
              <Skeleton variant="rect" height={30}></Skeleton>
            </div>
          </div>
        );
        break;
      case "bar":
        let { barCount = 30 } = data;
        let bars = [];
        for (let i = 0; i < barCount; i++) {
          let h = Math.floor(10 + Math.random() * 290);
          bars.push(
            <div key={"b" + i} className="sk-bar-val ">
              <Skeleton variant="rect" height={10} width={h % 300}></Skeleton>
            </div>
          );
        }
        toReturn = (
          <div className="sk-bar">
            {" "}
            <div className="p-2 sk-head">
              <Skeleton variant="rect" height={30}></Skeleton>
            </div>
            <div className="p-2 sk-body">{bars} </div>
            <div className="p-2 sk-foot">
              <Skeleton variant="rect" height={30}></Skeleton>
            </div>
          </div>
        );
        break;
      case "chart":
        let { colCount = 10 } = data;
        let columnArr = [];
        for (let i = 0; i < colCount; i++) {
          let h = Math.floor(10 + Math.random() * 290);
          columnArr.push(
            <div key={"b" + i} className="sk-column">
              <Skeleton variant="rect" height={h} width={30}></Skeleton>
            </div>
          );
        }
        toReturn = (
          <div className="sk-chart">
            {" "}
            <div className="p-2 sk-head">
              <Skeleton variant="rect" height={30}></Skeleton>
            </div>
            <div className="p-2 sk-body">{columnArr} </div>
            <div className="p-2 sk-foot">
              <Skeleton variant="rect" height={30}></Skeleton>
            </div>
          </div>
        );
        break;
      case "config":
        let { config = [], wrapperClassName = "" } = data;
        let configArr = [];
        config.forEach((d, i) => {
          let {
            height = 20,
            width = "100%",
            variant = "rect",
            className = "",
          } = d;
          configArr.push(
            <div key={"c" + i} className={className}>
              <Skeleton
                variant={variant}
                height={height}
                width={width}
              ></Skeleton>
            </div>
          );
        });
        toReturn = (
          <div className={"sk-chart " + wrapperClassName}>{configArr}</div>
        );
        break;
      case "area":
        let { areaCount = 10 } = data;
        areaCount *= 20;
        let areas = [];
        for (let i = 0; i < areaCount; i++) {
          let v = Math.floor(1 + Math.random() * 5);
          if (i < areaCount / 5.3) {
            if (i % 5 === 0) {
              h += v;
            }
          } else if (i < areaCount / 5) {
            if (i % 5 === 0) {
              h -= v;
            }
          } else if (i < areaCount / 4.6) {
            if (i % 5 === 0) {
              h -= v;
            }
          } else if (i < areaCount / 4) {
            if (i % 5 === 0) {
              h += v;
            }
          } else if (i < areaCount / 3.7) {
            if (i % 5 === 0) {
              h += v;
            }
          } else if (i < areaCount / 3.3) {
            if (i % 5 === 0) {
              h -= v;
            }
          } else if (i < areaCount / 3) {
            if (i % 5 === 0) {
              h -= v;
            }
          } else if (i < areaCount / 2.5) {
            if (i % 5 === 0) {
              h -= v;
            }
          } else if (i < areaCount / 2) {
            if (i % 5 === 0) {
              h += v;
            }
          } else if (i < areaCount / 1.8) {
            if (i % 5 === 0) {
              h -= v;
            }
          } else if (i < areaCount / 1.5) {
            if (i % 5 === 0) {
              h -= v;
            }
          } else if (i < areaCount / 1.2) {
            if (i % 5 === 0) {
              h += v;
            }
          } else {
            if (i % 5 === 0) {
              h += v;
            }
          }
          areas.push(
            <div key={"b" + i} className="sk-area-bar">
              <Skeleton variant="rect" height={h % 300} width={2}></Skeleton>
            </div>
          );
        }
        toReturn = (
          <div className="sk-area">
            {" "}
            <div className="p-2 sk-head">
              <Skeleton variant="rect" height={30}></Skeleton>
            </div>
            <div className="p-2 sk-body">{areas} </div>
            <div className="p-2 sk-foot">
              <Skeleton variant="rect" height={30}></Skeleton>
            </div>
          </div>
        );
        break;
      case "line":
        let { lineCount = 10 } = data;
        lineCount *= 40;
        let lines = [];
        for (let i = 0; i < lineCount; i++) {
          let v = Math.floor(1 + Math.random() * 10);
          if (i < lineCount / 5.3) {
            if (i % 5 === 0) {
              h += v;
            }
          } else if (i < lineCount / 5) {
            if (i % 5 === 0) {
              h -= v;
            }
          } else if (i < lineCount / 4.6) {
            if (i % 5 === 0) {
              h -= v;
            }
          } else if (i < lineCount / 4) {
            if (i % 5 === 0) {
              h += v;
            }
          } else if (i < lineCount / 3.7) {
            if (i % 5 === 0) {
              h += v;
            }
          } else if (i < lineCount / 3.3) {
            if (i % 5 === 0) {
              h -= v;
            }
          } else if (i < lineCount / 3) {
            if (i % 5 === 0) {
              h -= v;
            }
          } else if (i < lineCount / 2.5) {
            if (i % 5 === 0) {
              h -= v;
            }
          } else if (i < lineCount / 2) {
            if (i % 5 === 0) {
              h += v;
            }
          } else if (i < lineCount / 1.8) {
            if (i % 5 === 0) {
              h -= v;
            }
          } else if (i < lineCount / 1.5) {
            if (i % 5 === 0) {
              h -= v;
            }
          } else if (i < lineCount / 1.2) {
            if (i % 5 === 0) {
              h += v;
            }
          } else {
            if (i % 5 === 0) {
              h += v;
            }
          }
          lines.push(
            <div key={"b" + i} className="sk-line-bar">
              <div>
                <Skeleton variant="rect" height={2} width={1}></Skeleton>
              </div>
              <div style={{ height: h + "px", width: "1px" }}></div>
            </div>
          );
        }
        toReturn = (
          <div className="sk-line">
            {" "}
            <div className="p-2 sk-head">
              <Skeleton variant="rect" height={30}></Skeleton>
            </div>
            <div className="p-2 sk-body">{lines} </div>
            <div className="p-2 sk-foot">
              <Skeleton variant="rect" height={30}></Skeleton>
            </div>
          </div>
        );
        break;
      default:
        break;
    }
    return <div className="sk-wrapper">{toReturn}</div>;
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomSkeleton);
