import {
  Button,
  Form,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
} from "reactstrap";
import { Component } from "react";

class AddAdminModal extends Component {
  state = {
    name: "",
    email: "",
  };

  componentDidMount() {}

  changeEmail($event) {
    this.setState({ email: $event.target.value });
  }
  changeName($event) {
    this.setState({ name: $event.target.value });
  }

  save() {
    const { name, email } = this.state;
    this.props.save({ name, email });
  }

  render() {
    return (
      <Modal
        isOpen={this.props.show}
        toggle={this.props.onHide}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <ModalHeader>
          <div id="contained-modal-title-vcenter">
            {this.props.modalData.heading}
          </div>
        </ModalHeader>
        <ModalBody className="add-user-form-body">
          <Form>
            <FormGroup>
              <label className="label form-lable">Email address</label>
              <input
                className="form-control"
                type="email"
                placeholder="name@example.com"
                value={this.state.email}
                onChange={this.changeEmail.bind(this)}
              />
            </FormGroup>
            <FormGroup>
              <label className="label form-lable">Full Name</label>
              <input
                className="form-control"
                type="text"
                placeholder="Will Smith"
                value={this.state.name}
                onChange={this.changeName.bind(this)}
              />
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button size="sm" variant="outline-dark" onClick={this.props.onHide}>
            Close
          </Button>
          <Button
            size="sm"
            variant="dark"
            disabled={
              this.state.name.length === 0 || this.state.email.length === 0
            }
            onClick={this.save.bind(this)}
          >
            Save
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default AddAdminModal;
