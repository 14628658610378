import React from "react";
import { useState } from "react";
import { useHistory } from "react-router";
import Logo from "../assets/images/logo_pink.png";

interface ContainerProps {}

const Header: React.FC<ContainerProps> = () => {
  let history = useHistory();
  let [showMenu, setShowMenu] = useState(false);

  return (
    <div className="header">
      <div className="title-bar">
        <div
          className="logo cursor-pointer"
          onClick={() => {
            history.push("/home");
          }}
        >
          <div className="logo-img">
            <img src={Logo} alt="logo" />
          </div>
          <div className="logo-text">
            <span className="conjusted">
              <span className="dance">
                {" "}
                <span className="highlight-text f-120p">B</span>
                <span>a</span>
                <span>s</span>
                <span>h</span>
              </span>{" "}
              <span className="dance-rev">
                <span className="highlight-text f-120p">I</span>
                <span>n</span>
              </span>{" "}
              <span className="dance">
                <span className="highlight-text f-120p">J</span>
                <span>a</span>
                <span>i</span>
                <span>p</span>
                <span>u</span>
                <span>r</span>
              </span>
            </span>
          </div>
        </div>
        <nav className="nav">
          <ul className="nav-list">
            <li
              className={
                "cursor-pointer" +
                (window.location.pathname === "/home" ? " highlighted" : "")
              }
            >
              Home
            </li>
            <li
              className={
                "cursor-pointer" +
                (window.location.pathname === "/upcoming-events"
                  ? " highlighted"
                  : "")
              }
              onClick={() => {
                history.push("/upcoming-events");
              }}
            >
              Upcoming Events
            </li>
          </ul>
          <ul className="nav-list-right">
            <li>
              {localStorage.getItem("token") ? (
                localStorage.getItem("email") === "admin@bashinjaipur.com" ? (
                  <button
                    className="login-btn"
                    onClick={() => {
                      history.push("/welcome");
                    }}
                  >
                    Go To Console
                  </button>
                ) : (
                  <i className="fa-solid fa-user" />
                )
              ) : (
                <button
                  className="login-btn"
                  onClick={() => {
                    history.push("/login");
                  }}
                >
                  Login
                </button>
              )}
            </li>
          </ul>
        </nav>
        <div className="nav-alt">
          {showMenu ? (
            <div className="menu-box">
              <div
                className="toggle-btn"
                onClick={() => {
                  setShowMenu(false);
                }}
              >
                <i className="fa-solid fa-xmark"></i>
              </div>
              <ul className="nav-alt-list">
                <li
                  className={
                    "cursor-pointer" +
                    (window.location.pathname === "/home" ? " highlighted" : "")
                  }
                >
                  Home
                </li>
                <li
                  className={
                    "cursor-pointer" +
                    (window.location.pathname === "/upcoming-events"
                      ? " highlighted"
                      : "")
                  }
                  onClick={() => {
                    history.push("/upcoming-events");
                  }}
                >
                  Upcoming Events
                </li>
                <li
                  className="cursor-pointer"
                  onClick={() => {
                    history.push("/login");
                  }}
                >
                  Login
                </li>
              </ul>
            </div>
          ) : (
            <div
              className="toggle-btn"
              onClick={() => {
                setShowMenu(true);
              }}
            >
              <i className="fa-solid fa-bars"></i>
            </div>
          )}
        </div>
      </div>
      <div className="tagline">
        <div className="tagline-left">
          <h1 className="header-text">
            Where<span className="highlight"> Jaipur </span>Comes to{" "}
            <span className="highlight"> Celebrate</span>
          </h1>
          <p className="desc-text">
            Go-to destination for anyone looking for a perfect party in the city
            with wide variety of events to offer, from small gatherings to
            large-scale celebrations.
          </p>
          <button
            className="action-btn-common"
            onClick={() => {
              history.push("/upcoming-events");
            }}
          >
            Upcoming Events
          </button>
        </div>
        <div className="tagline-right"></div>
      </div>
    </div>
  );
};

export default Header;
