import CustomSkeleton from "../../UIElements/CustomSkeleton";

const JWT = () => {
  return (
    <div id="token">
      <div>
        <CustomSkeleton type="catalog" />
      </div>
      <div>
        <CustomSkeleton type="catalog-alt" />
      </div>
      <div>
        <CustomSkeleton type="top-filter" data={{ count: 1 }} />
        <CustomSkeleton type="top-filter" data={{ count: 2 }} />
        <CustomSkeleton type="top-filter" data={{ count: 3 }} />
        <CustomSkeleton type="top-filter" data={{ count: 4 }} />
        <CustomSkeleton type="top-filter" data={{ count: 5 }} />
        <CustomSkeleton type="top-filter" data={{ count: 6 }} />
        <CustomSkeleton type="top-filter" data={{ count: 7 }} />
        <CustomSkeleton type="top-filter" data={{ count: 8 }} />
        <CustomSkeleton type="top-filter" data={{ count: 9 }} />
      </div>

      <div>
        <CustomSkeleton
          type="config"
          data={{
            wrapperClassName: "grid-1-1fr",
            config: [
              {
                height: 30,
                width: 100,
                variant: "circle",
                className: "d-inline p-1",
              },
              {
                height: 30,
                width: 200,
                variant: "circle",
                className: "d-inline p-1",
              },
              {
                height: 30,
                variant: "rect",
                className: "d-block p-1",
              },
            ],
          }}
        />
      </div>

      <div>
        <CustomSkeleton type="local-filter" data={{ totalItems: 1 }} />
        <CustomSkeleton type="local-filter" data={{ totalItems: 2 }} />
        <CustomSkeleton type="local-filter" data={{ totalItems: 3 }} />
        <CustomSkeleton type="local-filter" data={{ totalItems: 4 }} />
        <CustomSkeleton type="local-filter" data={{ totalItems: 5 }} />
        <CustomSkeleton type="local-filter" data={{ totalItems: 6 }} />
        <CustomSkeleton type="local-filter" data={{ totalItems: 7 }} />
        <CustomSkeleton type="local-filter" data={{ totalItems: 8 }} />
        <CustomSkeleton type="local-filter" data={{ totalItems: 9 }} />
      </div>
      <br />
      <div>
        <CustomSkeleton type="table" data={{ rows: 20, columns: 10 }} />
      </div>
      <div>
        <CustomSkeleton type="chart" data={{ colCount: 10 }} />
      </div>
      <div>
        <CustomSkeleton type="bar" data={{ barCount: 30 }} />
      </div>
      <div>
        <CustomSkeleton type="area" data={{ areaCount: 20 }} />
      </div>
      <div>
        <CustomSkeleton type="line" data={{ lineCount: 10 }} />
      </div>
      {localStorage.getItem("token")}
    </div>
  );
};

export default JWT;
