import React from "react";
import { ReactComponent as ContibutorsIcon } from "../assets/svgs/contibutorsIcon.svg";
// import { ReactComponent as JasonImage } from "../assets/svgs/jasonImage.svg";
// import { ReactComponent as RatingStar } from "../assets/svgs/ratingStar.svg";
// import { ReactComponent as AlexImage } from "../assets/svgs/alexImage.svg";
// import { ReactComponent as LizzieImage } from "../assets/svgs/lizzieImage.svg";

interface ContainerProps {}

const ContributorsSection: React.FC<ContainerProps> = () => {
  return (
    <div className="parent-tag">
      <div className="contributors-section">
        <div className="section-content">
          <div className="section-logo">
            <ContibutorsIcon />
          </div>
          <div className="sectiion-text">
            <h1 className="section-heading">
              Potential Sponsors, Taking Our Events to The Next Level
            </h1>
            <p className="desc">
              The events are already well-planned, high-profile and have a
              strong foundation but with the help of potential sponsors, it
              could reach new heights.
            </p>
          </div>
        </div>
        <div className="section-cards">
          <div className="helper-card">
            <div className="first-line">
              <h1 className="line-start">How to register for an event?</h1>
              {/* <div className="share">
                <button className="action-btn">1 SHARE</button>
              </div> */}
            </div>
            <div className="card-desc">
              <p className="desc">
                Choose an amazing event of your choice and fill in some basic
                information to get started with your registration for an
                unforgettable experience.
              </p>
            </div>
          </div>
          {/* <div className="applicant-info">
            <div className="applicants">
              <h1 className="applicants-heading">3 applicants</h1>
              <div className="applicants-list">
                <div className="each-applicant">
                  <div className="applicant-img">
                    <JasonImage />
                  </div>
                  <div className="applicant-name">Jason Kwon</div>
                  <div className="rating-star">
                    <RatingStar />
                  </div>
                  <div className="applicant-rating">4.8</div>
                </div>
                <div className="each-applicant">
                  <div className="applicant-img">
                    <AlexImage />
                  </div>
                  <div className="applicant-name">Alex Rose</div>
                  <div className="rating-star">
                    <RatingStar />
                  </div>
                  <div className="applicant-rating">4.5</div>
                </div>
                <div className="each-applicant">
                  <div className="applicant-img">
                    <LizzieImage />
                  </div>
                  <div className="applicant-name">Lizzie Ulrich</div>
                  <div className="rating-star">
                    <RatingStar />
                  </div>
                  <div className="applicant-rating">4.2</div>
                </div>
              </div>
            </div>
            <div className="applicant-detail-card">
              <div className="applicant-intro">
                <div className="applicant-img">
                  <AlexImage />
                </div>
                <h1 className="applicant-name">Alex Rose</h1>
                <p className="applicant-role">UX Writer</p>
              </div>
              <div className="score-row">
                <div className="commits">4 commits</div>
                <div className="rating-star">
                  <RatingStar />
                </div>
                <div className="applicant-rating">4.8</div>
              </div>
              <div className="view-profile-tab">
                <button className="action-btn">View profile</button>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default ContributorsSection;
