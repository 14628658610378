import {
  Navbar,
  Collapse,
  Nav,
  NavbarBrand,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
  UncontrolledDropdown,
} from "reactstrap";
import logo from "../assets/images/icon.png";
import user from "../assets/images/user.jpg";

const Header = () => {
  const showMobilemenu = () => {
    document.getElementById("sidebarArea").classList.toggle("showSidebar");
  };

  const logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("name");
    localStorage.removeItem("email");
    window.location.href = "/";
  };
  return (
    <Navbar
      color="dark"
      dark
      expand="md"
      onClick={() => {
        console.log("done");
      }}
      className="height-6rem"
    >
      <div className="d-flex align-items-center">
        <NavbarBrand href="/" className="d-lg-none">
          <img src={logo} alt="logo" style={{ width: "5rem" }} />
        </NavbarBrand>
        <Button
          color="dark"
          className="d-lg-none"
          onClick={() => showMobilemenu()}
        >
          <i className="bi bi-list"></i>
        </Button>
      </div>
      <div className="hstack gap-2">
        <UncontrolledDropdown className="d-sm-block d-md-none">
          <DropdownToggle color="dark">
            <img
              src={user}
              alt="profile"
              className="rounded-circle"
              width="30"
            ></img>
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem header>Hello User,</DropdownItem>
            <DropdownItem disabled>My Account</DropdownItem>
            <DropdownItem disabled>Edit Profile</DropdownItem>
            <DropdownItem divider />
            {localStorage.getItem("admin-token") ? (
              <DropdownItem
                onClick={() => {
                  localStorage.setItem(
                    "token",
                    localStorage.getItem("admin-token")
                  );
                  localStorage.setItem(
                    "name",
                    localStorage.getItem("admin-name")
                  );
                  localStorage.setItem(
                    "email",
                    localStorage.getItem("admin-email")
                  );
                  if (localStorage.getItem("admin-profilePic")) {
                    localStorage.setItem(
                      "profilePic",
                      localStorage.getItem("admin-profilePic")
                    );
                  } else {
                    localStorage.removeItem("profilePic");
                  }
                  localStorage.removeItem("admin-token");
                  localStorage.removeItem("admin-name");
                  localStorage.removeItem("admin-email");
                  localStorage.removeItem("admin-profilePic");
                  localStorage.removeItem("manager-token");
                  window.location.href = "/";
                }}
              >
                Switch to Admin
              </DropdownItem>
            ) : null}
            <DropdownItem onClick={logout}>Logout</DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      </div>

      <Collapse navbar>
        <Nav className="me-auto" navbar></Nav>
        <UncontrolledDropdown>
          <DropdownToggle color="dark">
            <img
              src={user}
              alt="profile"
              className="rounded-circle"
              width="30"
            ></img>
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem header>Hello User,</DropdownItem>
            <DropdownItem disabled>My Account</DropdownItem>
            <DropdownItem disabled>Edit Profile</DropdownItem>
            <DropdownItem divider />
            {localStorage.getItem("admin-token") ? (
              <DropdownItem
                onClick={() => {
                  localStorage.setItem(
                    "token",
                    localStorage.getItem("admin-token")
                  );
                  localStorage.setItem(
                    "name",
                    localStorage.getItem("admin-name")
                  );
                  localStorage.setItem(
                    "email",
                    localStorage.getItem("admin-email")
                  );
                  if (localStorage.getItem("admin-profilePic")) {
                    localStorage.setItem(
                      "profilePic",
                      localStorage.getItem("admin-profilePic")
                    );
                  } else {
                    localStorage.removeItem("profilePic");
                  }
                  localStorage.removeItem("admin-token");
                  localStorage.removeItem("admin-name");
                  localStorage.removeItem("admin-email");
                  localStorage.removeItem("admin-profilePic");
                  localStorage.removeItem("manager-token");
                  window.location.href = "/";
                }}
              >
                Switch to Admin
              </DropdownItem>
            ) : null}
            <DropdownItem onClick={logout}>Logout</DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      </Collapse>
    </Navbar>
  );
};

export default Header;
