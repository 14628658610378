import {
  convertToRaw,
  EditorState,
  convertFromRaw,
  CompositeDecorator,
} from "draft-js";
import moment from "moment";
import { Component } from "react";
import { formDataCall, postCall } from "../../http-services";
import { BackendAPIs, getBaseApi } from "../../store/constant";
import CustomEditor from "../../UIElements/CustomEditor";
import { stateToHTML } from "draft-js-export-html";
import jQuery from "jquery";
import { connect } from "react-redux";
import actionTypes from "../../store/actions";

export const linkStrategy = (contentBlock, callback, contentState) => {
  contentBlock.findEntityRanges((character) => {
    const entityKey = character.getEntity();
    return (
      entityKey !== null &&
      contentState.getEntity(entityKey).getType() === "LINK"
    );
  }, callback);
};

export const Link = (props) => {
  const { contentState, entityKey } = props;
  const { url } = contentState.getEntity(entityKey).getData();
  return (
    <a
      className="link"
      href={url}
      rel="noopener noreferrer"
      target="_blank"
      aria-label={url}
    >
      {props.children}
    </a>
  );
};

const decorator = new CompositeDecorator([
  {
    strategy: linkStrategy,
    component: Link,
  },
]);

const stateToHTMLWrapper = (content) => {
  return stateToHTML(content, {
    inlineStyles: {
      red: {
        style: {
          color: "rgba(255, 0, 0, 1.0)",
        },
      },
      orange: {
        style: {
          color: "rgba(255, 127, 0, 1.0)",
        },
      },
      yellow: {
        style: {
          color: "rgba(180, 180, 0, 1.0)",
        },
      },
      green: {
        style: {
          color: "rgba(0, 180, 0, 1.0)",
        },
      },
      blue: {
        style: {
          color: "rgba(0, 0, 255, 1.0)",
        },
      },
      indigo: {
        style: {
          color: "rgba(75, 0, 130, 1.0)",
        },
      },
      violet: {
        style: {
          color: "rgba(127, 0, 255, 1.0)",
        },
      },
      pink: {
        style: {
          color: "rgba(255, 20, 147, 1.0)",
        },
      },
      CODE: {
        style: {
          backgroundColor: "rgba(0, 0, 0, 0.05)",
          fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
          fontSize: 16,
          padding: 2,
        },
      },
    },
  });
};

class CreateEventAdmin extends Component {
  state = {
    editorState: EditorState.createEmpty(),
    title: "",
    shortDesc: "",
    ticketPrice: "",
    eventDate: "",
    image: null,
    tempImage: null,
    tempTicketPrice: "",
    tempEventDate: "",
    events: [],
    tempEditorState: EditorState.createEmpty(),
    tempEditorStateId: null,
    tempContent: null,
    nomorelogs: false,
    tempTitle: "",
    tempShortDesc: "",
    copyTempShortDesc: "",
    copyTempTitle: "",
    copyTempTicketPrice: "",
    copyTempEventDate: "",
  };

  componentDidMount() {
    this.loadEvents();
  }
  loadEvents() {
    postCall(this.props.setLoading)(
      getBaseApi() + BackendAPIs.eventMgmt.getEvents,
      {
        offset: this.state.events.length,
      }
    ).then((resp) => {
      if (resp.events && resp.events.length) {
        this.setState({
          events: [...this.state.events, ...resp.events],
        });
      } else {
        this.setState({
          nomorelogs: true,
        });
      }
    });
  }

  saveEvent() {
    if (this.state.editorState && this.state.title && this.state.shortDesc) {
      let formData = new FormData();
      formData.append("title", this.state.title);
      formData.append("shortDesc", this.state.shortDesc);
      formData.append(
        "eventHtml",
        stateToHTMLWrapper(this.state.editorState.getCurrentContent())
      );
      formData.append(
        "event",
        JSON.stringify(convertToRaw(this.state.editorState.getCurrentContent()))
      );
      formData.append("image", this.state.image);
      formData.append("ticketPrice", this.state.ticketPrice);
      formData.append("eventDate", this.state.eventDate);

      formDataCall(this.props.setLoading)(
        getBaseApi() + BackendAPIs.eventMgmt.saveEvent,
        formData
      ).then((resp) => {
        this.setState({
          events: [resp.eventVal, ...this.state.events],
          editorState: EditorState.createEmpty(),
          title: "",
          shortDescL: "",
          image: null,
          ticketPrice: "",
          eventDate: "",
        });

        jQuery('input[type="file"]').val(null);
      });
    }
  }

  editEvent(d) {
    if (this.state.tempEditorStateId) {
      let tempEditorStateContent = stateToHTMLWrapper(
        this.state.tempEditorState.getCurrentContent()
      );
      if (
        tempEditorStateContent !== this.state.tempContent ||
        this.state.tempTitle !== this.state.copyTempTitle ||
        this.state.tempShortDesc !== this.state.copyTempShortDesc ||
        this.state.tempTicketPrice !== this.state.copyTempTicketPrice ||
        this.state.tempEventDate !== this.state.copyTempEventDate
      ) {
        alert("Please save or discard previous edit.");
        return;
      }
    }
    let content = convertFromRaw(d.event);
    this.setState({
      tempEditorStateId: d._id,
      tempEditorState: EditorState.createWithContent(content),
      tempContent: stateToHTMLWrapper(content),
      tempTitle: d.title,
      copyTempTitle: d.title,
      tempShortDesc: d.shortDesc,
      copyTempShortDesc: d.shortDesc,
      tempTicketPrice: d.ticketPrice,
      copyTempTicketPrice: d.ticketPrice,
      tempEventDate: d.eventDate,
      copyTempEventDate: d.eventDate,
    });
  }

  discard() {
    this.setState({
      tempEditorStateId: null,
      tempEditorState: EditorState.createEmpty(),
      tempContent: null,
      tempTitle: "",
      copyTempTitle: "",
      tempShortDesc: "",
      copyTempShortDesc: "",
      tempImage: null,
      tempTicketPrice: "",
      copyTempTicketPrice: "",
      tempEventDate: "",
      copyTempEventDate: "",
    });
  }

  performEdit() {
    let eventIndex = this.state.events.findIndex((d) => {
      return d._id === this.state.tempEditorStateId;
    });
    if (eventIndex !== -1) {
      let rawEvent = convertToRaw(
        this.state.tempEditorState.getCurrentContent()
      );
      let formData = new FormData();
      formData.append("title", this.state.tempTitle);
      formData.append("shortDesc", this.state.tempShortDesc);
      formData.append("eventId", this.state.tempEditorStateId);
      formData.append(
        "eventHtml",
        stateToHTMLWrapper(this.state.tempEditorState.getCurrentContent())
      );
      formData.append("event", JSON.stringify(rawEvent));
      formData.append("image", this.state.tempImage);
      formData.append("ticketPrice", this.state.tempTicketPrice);
      formData.append("eventDate", this.state.tempEventDate);

      formDataCall(this.props.setLoading)(
        getBaseApi() + BackendAPIs.eventMgmt.performEdit,
        formData
      ).then((resp) => {
        let events = [...this.state.events];
        let event = { ...events[eventIndex] };
        event.event = rawEvent;
        event.title = this.state.tempTitle;
        event.shortDesc = this.state.tempShortDesc;
        event.ticketPrice = this.state.tempTicketPrice;
        event.eventDate = this.state.tempEventDate;
        events[eventIndex] = event;
        event.imagePath = resp.imagePath;
        this.setState({
          events,
          tempEditorStateId: null,
          tempEditorState: EditorState.createEmpty(),
          tempContent: null,
          tempTitle: "",
          copyTempTitle: "",
          tempShortDesc: "",
          copyTempShortDesc: "",
          tempImage: null,
          tempTicketPrice: "",
          copyTempTicketPrice: "",
          tempEventDate: "",
          copyTempEventDate: "",
        });
      });
    }
  }

  markArchive(id) {
    let eventIndex = this.state.events.findIndex((d) => {
      return d._id === id;
    });
    if (eventIndex !== -1) {
      postCall(this.props.setLoading)(
        getBaseApi() + BackendAPIs.eventMgmt.markArchive,
        {
          eventId: id,
        }
      ).then((resp) => {
        let events = [...this.state.events];
        let event = { ...events[eventIndex] };
        event.archived = true;
        events[eventIndex] = event;
        this.setState({
          events,
        });
      });
    }
  }

  markUnarchive(id) {
    let eventIndex = this.state.events.findIndex((d) => {
      return d._id === id;
    });
    if (eventIndex !== -1) {
      postCall(this.props.setLoading)(
        getBaseApi() + BackendAPIs.eventMgmt.markUnarchive,
        {
          eventId: id,
        }
      ).then((resp) => {
        let events = [...this.state.events];
        let event = { ...events[eventIndex] };
        event.archived = false;
        events[eventIndex] = event;
        this.setState({
          events,
        });
      });
    }
  }

  render() {
    return (
      <div className="position-relative">
        <div className="paper-layout ps-2 pe-2">
          <i
            className="fa fa-save icon-right z-500"
            onClick={() => this.saveEvent()}
          ></i>

          <br />
          <br />
          <div className="split-1-1">
            <div className="ps-2 pe-2">
              <input
                type="text"
                placeholder="Title"
                className="form-control"
                value={this.state.title}
                onChange={(e) => {
                  this.setState({ title: e.target.value });
                }}
              />
            </div>
            <div className="ps-2 pe-2">
              <input
                type="file"
                className="form-control"
                onChange={(e) => {
                  this.setState({ image: e.target.files[0] });
                }}
              />
            </div>
          </div>
          <br />
          <div className="grid-1fr">
            <div className="ps-2 pe-2">
              <input
                type="text"
                placeholder="Short Description"
                className="form-control"
                value={this.state.shortDesc}
                onChange={(e) => {
                  this.setState({ shortDesc: e.target.value });
                }}
              />
            </div>
          </div>
          <br />
          <div className="split-1-1">
            <div className="ps-2 pe-2">
              <input
                type="text"
                placeholder="Ticket Price"
                className="form-control"
                value={this.state.ticketPrice}
                onChange={(e) => {
                  if (!isNaN(e.target.value)) {
                    this.setState({ ticketPrice: e.target.value });
                  }
                }}
              />
            </div>
            <div className="ps-2 pe-2">
              <input
                type="date"
                placeholder="Event Date"
                className="form-control"
                value={this.state.eventDate}
                onChange={(e) => {
                  this.setState({ eventDate: e.target.value });
                }}
              />
            </div>
          </div>
          <br />
          <CustomEditor
            placeholder="Write about event.."
            onChange={(editorState) => {
              this.setState({ editorState });
            }}
            editorState={this.state.editorState}
          />
        </div>
        <br />
        <hr />
        <div>
          {this.state.events.map((d, i) => {
            return this.state.tempEditorStateId === d._id ? (
              <div
                style={{
                  background: "#fff",
                  boxShadow: "2px 4px 8px rgba(0,0,0,.5)",
                }}
                className="p-2 mb-2 position-relative"
                key={i + "alt"}
              >
                <span className="icons-right z-500">
                  <i
                    className="fa fa-reply cursor-pointer pt-2 pe-2"
                    onClick={() => {
                      this.discard();
                    }}
                  ></i>{" "}
                  <i
                    className="fa fa-save cursor-pointer pt-2"
                    onClick={() => {
                      this.performEdit();
                    }}
                  ></i>
                </span>
                <br />
                <br />
                <div className="split-1-1">
                  <div className="ps-2 pe-2">
                    <input
                      type="text"
                      placeholder="Title"
                      className="form-control"
                      value={this.state.tempTitle}
                      onChange={(e) => {
                        this.setState({ tempTitle: e.target.value });
                      }}
                    />
                  </div>
                  <div className="ps-2 pe-2">
                    <input
                      type="file"
                      className="form-control"
                      onChange={(e) => {
                        this.setState({ tempImage: e.target.files[0] });
                      }}
                    />
                  </div>
                </div>
                <br />
                <div className="grid-1fr">
                  <div className="ps-2 pe-2">
                    <input
                      type="text"
                      placeholder="Short Description"
                      className="form-control"
                      value={this.state.tempShortDesc}
                      onChange={(e) => {
                        this.setState({ tempShortDesc: e.target.value });
                      }}
                    />
                  </div>
                </div>
                <br />
                <div className="split-1-1">
                  <div className="ps-2 pe-2">
                    <input
                      type="text"
                      placeholder="Ticket Price"
                      className="form-control"
                      value={this.state.tempTicketPrice}
                      onChange={(e) => {
                        if (!isNaN(e.target.value)) {
                          this.setState({ tempTicketPrice: e.target.value });
                        }
                      }}
                    />
                  </div>
                  <div className="ps-2 pe-2">
                    <input
                      type="date"
                      placeholder="Event Date"
                      className="form-control"
                      value={this.state.tempEventDate}
                      onChange={(e) => {
                        this.setState({ tempEventDate: e.target.value });
                      }}
                    />
                  </div>
                </div>
                <br />
                <CustomEditor
                  placeholder="Write event.."
                  editorState={this.state.tempEditorState}
                  onChange={(editorState) => {
                    this.setState({ tempEditorState: editorState });
                  }}
                />
              </div>
            ) : (
              <div
                style={{
                  background: "#fff",
                  minHeight: "100px",
                  boxShadow: "2px 4px 8px rgba(0,0,0,.5)",
                }}
                className="p-2 mb-2 position-relative grid-1-3fr"
                key={i}
              >
                <img
                  alt="event title"
                  src={
                    d.imagePath
                      ? d.imagePath
                      : "/assets/images/Visual COGS_svg.svg"
                  }
                  className="mxw-100"
                />
                <div>
                  <span className="icons-right z-500">
                    <i
                      className="fa fa-edit cursor-pointer pe-2"
                      onClick={() => this.editEvent(d)}
                    ></i>
                    {d.archived ? (
                      <i
                        className="fa fa-regular fa-circle-up cursor-pointer"
                        onClick={() => this.markUnarchive(d._id)}
                      ></i>
                    ) : (
                      <>
                        <i
                          className="fa fa-regular fa-circle-down cursor-pointer text-danger-light"
                          onClick={() => this.markArchive(d._id)}
                        ></i>
                      </>
                    )}
                  </span>
                  {d.archived ? (
                    <span className="right-text-rotated">
                      <i className="fa fa-tag pe-1" aria-hidden="true"></i>{" "}
                      Archived
                    </span>
                  ) : d.approved ? (
                    <span className="right-text-rotated-blue">
                      <i className="fa fa-check pe-1" aria-hidden="true"></i>
                      Approved
                    </span>
                  ) : null}
                  <br />
                  <br />
                  <h1 className="event-title">{d.title} </h1>
                  <p>{d.shortDesc}</p>
                  <p>
                    <b>Event Date: </b>
                    {moment(d.eventDate).format("MMM DD, YYYY")}
                  </p>
                  <p>
                    <b>Fare: </b>
                    {parseFloat(d.ticketPrice).toLocaleString("en-IN", {
                      style: "currency",
                      currency: "INR",
                    })}
                  </p>
                  <CustomEditor
                    editorState={EditorState.createWithContent(
                      convertFromRaw(d.event),
                      decorator
                    )}
                    readOnly={true}
                  />
                </div>
              </div>
            );
          })}
          {this.state.nomorelogs ? null : (
            <div className="pull-right">
              <sub
                className="btn-link cursor-pointer"
                onClick={this.loadEvents.bind(this)}
              >
                load more...
              </sub>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    setLoading: (isLoading) =>
      dispatch({
        type: actionTypes.SET_LOADING,
        isLoading,
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateEventAdmin);
