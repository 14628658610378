import React from "react";
interface ContainerProps {}

const HowToImproveSection: React.FC<ContainerProps> = () => {
  return (
    <div className="how-to-improve-parent">
      <div className="how-to-improve">
        <div className="page-heading">
          <h1 className="primary-heading">Help Us Raise The Bar</h1>
          <p className="heading-desc">
            We are committed to providing the best possible event experience.We
            values the opinions and feedback of the attendees and are open to
            suggestions for improving the events.
          </p>
        </div>
        <div className="helping-cards">
          <div className="each-card">
            <div className="card-info">
              <h1 className="card-heading">
                Ideate a list of features based on the Sociocracy model
              </h1>
              <p className="card-desc">
                We’re looking for someone interested in business theory and
                research that’ll help us bridge the gap between our s...
              </p>
            </div>
            <div className="bottom-line">
              <div className="apply-by">16 May, 2022</div>
            </div>
          </div>
          <div className="each-card">
            <div className="card-info">
              <h1 className="card-heading">Update documentation with FAQs</h1>
              <p className="card-desc">
                Review our community chat for the most frequently asked
                questions and document answers for our product docs.
              </p>
            </div>
            <div className="bottom-line">
              <div className="apply-by">20 May, 2022</div>
            </div>
          </div>
          <div className="each-card">
            <div className="card-info">
              <h1 className="card-heading">Tutorial video series</h1>
              <p className="card-desc">
                Create a series of video tutorials that cover everything from
                start to finish on using Open Enterprise.
              </p>
            </div>
            <div className="bottom-line">
              <div className="apply-by">26 May, 2022</div>
            </div>
          </div>
        </div>
        {/* <div className="view-more-button">
          <button className="action-btn">View more</button>
        </div> */}
      </div>
    </div>
  );
};

export default HowToImproveSection;
