import React from "react";
import { Component } from "react";
import { connect } from "react-redux";
import { Table } from "reactstrap";
import Aux from "../hoc/_Aux";

class CustomTable extends Component {
  render() {
    let columns = this.props.columns || [];
    let data = this.props.data || [];
    return (
      <Aux>
        {columns.length ? (
          <Table bordered={true}>
            <thead>
              <tr>
                {columns.map((d, i) => {
                  return <th key={i}>{d.title}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {data.map((v, i1) => {
                return (
                  <tr key={i1}>
                    {columns.map((col, i) => {
                      return <td key={i}>{v[col.name]}</td>;
                    })}
                  </tr>
                );
              })}
            </tbody>
          </Table>
        ) : null}
      </Aux>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomTable);
