import React from "react";
import { ReactComponent as OnboardSectionIcon } from "../assets/svgs/onboardSectionIcon.svg";
import Girl1 from "../assets/images/girl1.png";

interface ContainerProps {}

const OnboardUserSection: React.FC<ContainerProps> = () => {
  return (
    <div className="onboard-user-parent">
      <div className="user-onboarding">
        {/* <div className="user-profile-card">
          <div className="user-intro-grid">
            <div className="user-img">
              <JassirImage />
            </div>
            <div className="user-intro">
              <div className="user-name">Jassir Jonis</div>
              <div className="user-class">Pre-seed angel investor</div>
            </div>
          </div>
          <div className="user-msg">
            Hey guys, love what you’re doing and I’m convinced that together
            we’ll achieve amazing things.
          </div>
          <div className="user-value-grid">
            <div className="user-value">
              <div className="value-param-name">COMMIT</div>
              <div className="value-param">10,000 DAI</div>
            </div>
            <div className="user-value">
              <div className="value-param-name">SHARES</div>
              <div className="value-param">20</div>
            </div>
          </div>
          <div className="vote-tab">
            <button className="action-btn">Go to vote</button>
          </div>
        </div> */}
        <div className="bg-party">
          <img className="party-girl1" src={Girl1} alt="Jumping Girl" />
        </div>
        <div className="right-body">
          <div className="onboarding-logo">
            <OnboardSectionIcon />
          </div>
          <div className="msg">
            <h1 className="msg-heading">New Party, New People, New Memories</h1>
            <p className="description">
              An invitation to break away from the ordinary and discover
              something new. An opportunity to make new connections, have new
              experiences and make memories that will last a lifetime. It's a
              call to action for people to join the event, and be a part of
              something fresh, exciting and unforgettable.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OnboardUserSection;
