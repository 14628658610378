import React from "react";
import actionTypes from "../../store/actions";
import { connect } from "react-redux";
import EventList from "../../components/EventList";
import MakeEvent from "../../components/MakeEvent";
import UpcomingEventsFooter from "../../components/UpcomingEventsFooter";
import Header from "../../components/Header";

interface EventsProps {}

const UpcomingEvents: React.FC<EventsProps> = () => {
  return (
    <div className="upcoming-events">
      <Header />
      <EventList />
      <MakeEvent />
      <UpcomingEventsFooter />
    </div>
  );
};

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    setLoading: (isLoading: any) =>
      dispatch({
        type: actionTypes.SET_LOADING,
        isLoading,
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UpcomingEvents);
